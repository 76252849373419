html {
	font-size: 14px;

	--color-primary: #0095ff;
	--color-primary-rgb: 0, 149, 255;
	--color-background-rgb: 255, 255, 255;
	--color-border: #767676;
	--color-page-background: #f6f5f3;

	overscroll-behavior: none; /* Prevents page bounce */
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMac SystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	overscroll-behavior: none; /* Prevents page bounce */

	background-color: var(--color-page-background);
}

p,
h2,
h3 {
	margin: 0;
}

hr {
	background-image: linear-gradient(
		to right,
		rgba(185, 193, 195, 0) 0%,
		rgba(185, 193, 195, 0.8) 30%,
		rgba(185, 193, 195, 0.8) 70%,
		rgba(185, 193, 195, 0) 100%
	);
	border: none;
	height: 1px;
	min-height: 1px;
	flex-shrink: 0;
}

.page-shadow {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
	box-shadow: rgb(0 0 0 / 25%) 0px 0px 55px inset, rgb(0 0 0 / 12%) 0px 0px 30px inset,
		rgb(0 0 0 / 12%) 0px 0px 6px inset, rgb(0 0 0 / 17%) 0px 0px 13px inset,
		rgb(0 0 0 / 9%) 0px 0px 5px inset;
	pointer-events: none;
}

input {
	font-size: 1.25rem;
	padding: 0.5rem 0.75rem;
	width: 8rem;
	border: 1px solid var(--color-border);
	border-radius: 0.15rem;
}

input:focus {
	outline: none;
	border: default;
	box-shadow: 0 0 0 3px rgba(var(--color-primary-rgb), 0.3);
}

input:disabled {
	background-color: rgba(239, 239, 239, 0.6);
	/* color: rgb(84, 84, 84); */
}

.number-input {
	font-size: 1.25rem;
	border: 1px solid var(--color-border);
	border-radius: 0.15rem;
	display: flex;
	align-items: center;
}
.number-input input {
	border: none;
	text-align: right;
	padding-left: 0;
	width: 100%;
	min-width: 8rem;
}
.number-input input:focus {
	outline: none;
	border: none;
	box-shadow: none;
}
.number-input:focus-within {
	box-shadow: 0 0 0 3px rgba(var(--color-primary-rgb), 0.3);
}

.number-input .currency-symbol {
	min-width: 0;
	width: 1.7rem;
	text-align: left;
	padding-left: 0.65rem;
	padding-right: 0.25rem;
	border-radius: 0;
	user-select: none;
}
.currency-symbol:not(.disabled) {
	background-color: white;
	color: #333;
}

.react-css-collapse-transition {
	transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.flex-row {
	display: flex;
	flex-direction: row;
}
.flex-row.center {
	align-items: center;
}

.page-container {
	width: 1284px;
	max-width: 94%;
	max-height: 100%;
	margin: 5.75rem auto 0 auto;
}

.navbar-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	padding-left: 1rem;
}
.navbar {
	width: calc(100% - 1rem);
	max-width: 1500px;
	margin: 0 auto 1.75rem auto;

	display: flex;
	flex-direction: row;
}

.nav-tab {
	width: 240px;
	height: 50px;
	clip-path: path(
		'M 0,0 C 5,0 15,0 20,10 C 35,50 30,50 45,50 L 195,50 C 210,50 205,50 220,10 C 225,0 235,0 240,0 Z'
	);
}

.navbar {
	filter: drop-shadow(0px 0px 6px rgb(0, 0, 0, 0.12))
		drop-shadow(0px 0px 13px rgb(0, 0, 0, 0.17)) drop-shadow(0px 0px 5px rgb(0, 0, 0, 0.09));
}
/* .nav-tab-wrapper {
	pointer-events: none;
} */
.nav-tab-wrapper.active {
	pointer-events: none; /* prevents unclickable space to the side */
	z-index: 3;
	filter: drop-shadow(0px 0px 8px rgb(0, 0, 0, 0.15));
}

.nav-tab {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	padding: calc(0.25rem + 5px) 0 0.25rem 0;
	font-size: 1.4rem;
	font-weight: bold;
	color: #666;
	background-color: #fafafa;
	text-decoration: none;
	cursor: pointer;
	user-select: none;

	margin-right: -50px;

	border: none;
	border-bottom: 5px solid transparent;

	z-index: 2;
	pointer-events: all; /* re-enables pointers disabled in the wrapper */
}

.nav-tab:focus:not(:focus-visible) {
	outline: none;
}

.nav-tab:hover,
.nav-tab:active,
.nav-tab.active {
	text-shadow: 1px 1px 2px rgba(30, 30, 30, 0.04), 1px 1px 8px rgba(30, 30, 30, 0.03),
		1px 1px 14px rgba(30, 30, 30, 0.02);
	color: var(--color-primary);
	background-color: white;
	z-index: 2;
}

.nav-tab.active {
	border-bottom-color: var(--color-primary);
}

.dashboard-section {
	border-radius: 1rem;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.03), 3px 3px 15px rgba(0, 0, 0, 0.05),
		3px 3px 25px rgba(0, 0, 0, 0.15);

	padding: 1.75rem 1.5rem 0rem 1.75rem; /* Expanded when larger screen size */

	background-color: white;
	margin-bottom: 2rem;
}

.client-name-title {
	font-size: 2rem;
	line-height: 2.5rem;
	/* letter-spacing: 4px; */
	font-weight: bold;
	margin-bottom: 1.6rem;
	margin-top: -0.5rem;
	display: inline-block;
	color: #333;

	text-shadow: 2px 2px 3px rgb(30 30 30 / 10%);
	border-bottom: 0.35rem solid;
	border-color: var(--color-primary);
}

.client-name-title-input {
	font-size: 2rem;
	font-weight: bold;
	margin-top: -0.5rem;
	margin-bottom: 1rem;
	min-width: 12rem;
	width: unset;
}

.setup-section {
	margin: 0 0 2rem 0;
}

.setup-section-title-row {
	display: flex;
	flex-direction: row;
	align-items: center;

	cursor: pointer;
	user-select: none;
	margin-bottom: 1.75rem;

	border-radius: 0.5rem;
	margin-left: -0.5rem;
	padding: 0.25rem 0 0.25rem 0.5rem;
}

.setup-section-title-row:hover {
	background-color: rgba(var(--color-primary-rgb), 0.1);
}

.setup-section-title {
	font-size: 1.75rem;
	padding-left: 0.75rem;

	display: inline-block;
}

.setup-section-title-svg svg {
	width: 1.5rem;
	height: 1.5rem;
	fill: var(--color-primary);
	transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.setup-section-title-svg.closed svg {
	transform: rotate(-90deg);
}

.setup-retirement-add-button {
	border: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	width: min-content;
	cursor: pointer;

	padding: 0 0.5rem 0 0.6rem;
}
.setup-retirement-add-button svg {
	fill: var(--color-primary);
	width: 1.5rem;
	height: 1.5rem;
	transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
.setup-retirement-add-button:hover svg {
	transform: scale(1.25);
}

.setup-delete-button {
	border: none;
	background-color: inherit;
	cursor: pointer;
	padding: 0;
}
.setup-delete-button svg {
	fill: #aaa;
	width: 1rem;
	height: 1rem;
	transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1),
		fill 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
.setup-delete-button:hover svg {
	fill: red;
	transform: scale(1.5);
}

.setup-retirement-grid {
	display: grid;
	grid-template-columns: max-content auto auto;
	width: min-content;
	height: fit-content;
	gap: 0.5rem;
	grid-gap: 1rem;
	align-items: center;
	margin: 0 0 4rem 2rem;
}

.setup-retirement-grid.three-input {
	grid-template-columns: max-content auto auto auto;
}
.setup-retirement-grid.four-input {
	grid-template-columns: max-content auto auto auto auto auto;
}

.setup-retirement-grid label {
	font-size: 1.15rem;
	font-weight: bold;
	margin-right: 0.5rem;
}

.setup-retirement-additional-section {
	margin-left: 2rem;
}

.setup-retirement-additional-title {
	font-size: 1.25rem;
	cursor: pointer;
	user-select: none;
	display: inline-block;
	/* border-left: 3px solid var(--color-primary); */
	padding-left: 0.75rem;
}

.setup-section-title-svg.additional-title svg {
	width: 1.25rem;
	height: 1.25rem;
}

.retirement-chart-container {
	width: 100%;
	flex-shrink: 1;
}

.variation-section-wrapper {
	margin-right: 3rem;
}

.variation-section {
	margin-bottom: 1.5rem;
	width: max-content;
}

.variation-section h3 {
	margin: 0 0 0.75rem 0;
}

.variation-section-buttons {
	margin-left: 0.5rem;
	margin-bottom: 0.25rem;
}

.variation-section-button {
	border-radius: 0.35rem;
	border: none;

	padding: 0.25rem 0.75rem 0.15rem 0.75rem;
	margin: 0 0.5rem 0.25rem 0;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.1);

	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 12px -3px; */

	color: var(--color-primary);
	background-color: white;
	font-weight: bold;
	font-size: 1.1rem;
	cursor: pointer;
}

.variation-section-button.active,
.variation-section-button:hover {
	color: white;
	background-color: var(--color-primary);
}

.variation-section-button-subtitle {
	display: inline-block;
	font-weight: bold;
	width: 4.5rem;
	border-left: 3px solid var(--color-primary);
	padding-left: 0.4rem;
}

.retirement-results-top-row {
	/* padding-bottom: 1rem; */
	padding: 0.5rem 2rem 1.5rem 2rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.retirement-results-top-row-section {
	text-align: center;
}

.retirement-results-top-row-title {
	font-size: 1.25rem;
	font-weight: bold;
}

.retirement-results-top-row-subtitle {
	font-size: 1rem;
	/* font-style: italic; */
	color: #888;
}
.retirement-results-top-row-value {
	padding: 0.5rem 0 0.25rem 0;
	font-size: 2rem;
	font-weight: bold;
	color: var(--color-primary);
}

.additional-details-section-wrapper {
	display: flex;
	justify-content: space-between;
}

.additional-details-section {
	display: grid;
	margin-bottom: auto;
}
.additional-details-section.three-column {
	grid-template-columns: max-content max-content auto;
}
.additional-details-section.two-column {
	grid-template-columns: max-content auto;
}

.additional-details-section * {
	padding: 2px 10px;
}

.checkbox {
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	/* border: 1px solid var(--color-border); */
	border: none;
	box-shadow: 0 0 0 1px var(--color-border);
	display: inline-block;
	flex-shrink: 0;
	cursor: pointer;
	user-select: none;
	padding: 0; /* To override default button styles */
	background-color: white;
	margin: 2px;
}

.checkbox svg {
	width: 70%;
	margin: 15%;
	display: inline-block;
	visibility: hidden;
}

.checkbox.small {
	width: 1.25rem;
	height: 1.25rem;
}

.checkbox:hover:not(.checked) svg {
	visibility: visible;
	fill: #bbb;
}

.checkbox.checked,
.checkbox:active {
	box-shadow: 0 0 0 2px var(--color-primary);
}
.checkbox.small.checked,
.checkbox.small:active {
	box-shadow: 0 0 0 1px var(--color-primary);
}
.checkbox.checked svg {
	visibility: visible;
	fill: var(--color-primary);
}

.probability-chart-wrapper {
	position: relative;
	margin: 0.75rem auto -2rem auto;
	padding-right: 0.75rem;
}
